import {
  Challenge,
  ChallengesService,
  GetAnswerRequestParams,
  ChallengeAnswer,
  WebAuthNEnrollment,
  GetChallengeRequestParams,
  ChallengeStatus,
} from '@agilicus/angular';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getIgnoreErrorsHeader } from '@app/http-interceptors/http-interceptor-utils';
import { Observable, firstValueFrom, from, forkJoin, of, takeUntil, concatMap, Subject, map, interval, catchError } from 'rxjs';
import { ChallengeType } from '@app/mfa-enroll/mfa-enroll.component';

export interface MFAChallengeDialogData {
  challenge: Challenge;
  webauthnEnrollmentList: Array<WebAuthNEnrollment>;
  challengeDescription?: string;
}

interface MFAChallengeForm {
  totp_auth_code: FormControl<string>;
}

@Component({
  selector: 'app-mfa-challenge-dialog',
  templateUrl: './mfa-challenge-dialog.component.html',
  styleUrls: ['./mfa-challenge-dialog.component.scss'],
})
export class MfaChallengeDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup<MFAChallengeForm>;
  public failed: boolean;
  public challengeType = ChallengeType;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MfaChallengeDialogComponent>,
    private challenges: ChallengesService,
    @Inject(MAT_DIALOG_DATA) public data: MFAChallengeDialogData
  ) {}

  public ngOnInit(): void {
    const defaults: MFAChallengeForm = {
      totp_auth_code: new FormControl(''),
    };
    this.form = this.fb.group(defaults);
    interval(5000)
      .pipe(
        concatMap((_) => {
          const getChallengeParams: GetChallengeRequestParams = {
            challenge_id: this.data.challenge.metadata.id,
          };
          return this.challenges.getChallenge(getChallengeParams);
        }),
        catchError((err) => {
          return of(undefined);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        if (!result) {
          this.dialogRef.close('error');
        }

        if (result.status?.state === ChallengeStatus.StateEnum.timed_out) {
          this.dialogRef.close('timedout');
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async submitTotpChallenge(): Promise<void> {
    this.failed = false;
    const code = this.form.get('totp_auth_code').value;
    const challengeAnswerParams: GetAnswerRequestParams = {
      challenge_id: this.data.challenge.metadata.id,
      challenge_answer: code,
      challenge_uid: this.data.challenge.spec.user_id,
      challenge_type: 'totp',
      allowed: true,
    };

    try {
      const answer = await firstValueFrom(this.challenges.getAnswer(challengeAnswerParams, 'body', getIgnoreErrorsHeader()));
      this.dialogRef.close('success');
      return;
    } catch (error) {
      console.log(error);
      this.failed = true;
      return;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close('cancelled');
  }

  public isSupportedWebauthn(mfaMethod: ChallengeType): boolean {
    return this.data.challenge.spec.challenge_types.includes(mfaMethod) && this.data.webauthnEnrollmentList.length > 0;
  }

  private encodeCredentialIdFromEndpoint(endpointId: string): ArrayBuffer | undefined {
    const credentialId = this.getDeviceFromEndpoint(endpointId)?.status?.credential_id;
    if (credentialId) {
      return this.stringToArrayBufferU8(atob(credentialId.replace(/_/g, '/').replace(/-/g, '+')));
    }
    return undefined;
  }

  private stringToBufBase(buf: ArrayBuffer, bufView: Uint8Array | Uint16Array, str: string): ArrayBuffer {
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  private stringToArrayBufferU8(str: string): ArrayBuffer {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    return this.stringToBufBase(buf, bufView, str);
  }

  private arrayBufferToStr(buf: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buf);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private removeProfileFromHostname(hostname: string): string {
    if (hostname.startsWith('profile.')) {
      return hostname.slice(8);
    }
    return hostname;
  }

  private getDeviceFromEndpoint(endpointId: string): WebAuthNEnrollment | undefined {
    return this.data.webauthnEnrollmentList?.find((device) => device.metadata?.id === endpointId);
  }

  private getCredInterface$(encodedCredentialIds: ArrayBuffer[]): Observable<Credential> {
    const allowCredentials: PublicKeyCredentialDescriptor[] = encodedCredentialIds.map((encodedCredentialId) => {
      return {
        type: 'public-key',
        id: encodedCredentialId,
      };
    });
    const getCredentialDefaultArgs: PublicKeyCredentialRequestOptions = {
      rpId: this.removeProfileFromHostname(window.location.hostname),
      allowCredentials: allowCredentials,
      challenge: this.stringToArrayBufferU8(this.data.challenge.status.public_challenge),
      timeout: 60000,
      userVerification: 'discouraged',
    };

    return from(navigator.credentials.get({ publicKey: getCredentialDefaultArgs }));
  }

  private getChallengeAnswer$(credInterface: Credential): Observable<ChallengeAnswer> {
    const cred = credInterface as PublicKeyCredential;
    const pkResponse = cred.response as AuthenticatorAssertionResponse;
    let userHandle = '';
    if (pkResponse.userHandle) {
      userHandle = this.arrayBufferToStr(pkResponse.userHandle);
    }
    const val = {
      user_id: this.data.challenge.spec.user_id,
      credential_id: cred.id,
      client_data: this.arrayBufferToStr(pkResponse.clientDataJSON),
      authenticator_data: this.arrayBufferToStr(pkResponse.authenticatorData),
      signature: this.arrayBufferToStr(pkResponse.signature),
      user_handle: userHandle,
    };
    const challengeAnswerParams: GetAnswerRequestParams = {
      allowed: true,
      challenge_type: ChallengeType.webauthn,
      challenge_answer: JSON.stringify(val),
      challenge_uid: this.data.challenge.spec.user_id,
      challenge_id: this.data.challenge.metadata.id,
    };
    return this.challenges.getAnswer(challengeAnswerParams);
  }

  private invokeWebAuthn$(endpointIds: string[]): Observable<[Credential, ChallengeAnswer]> {
    const encodedCredentialIds = endpointIds.map((endpointId) => {
      return this.encodeCredentialIdFromEndpoint(endpointId);
    });

    if (encodedCredentialIds.length === 0) {
      return forkJoin([of(undefined), of(undefined)]);
    }

    return this.getCredInterface$(encodedCredentialIds).pipe(
      concatMap((credInterfaceResp) => {
        if (!credInterfaceResp) {
          return forkJoin([of(undefined), of(undefined)]);
        }

        return this.getChallengeAnswer$(credInterfaceResp).pipe(
          map((challengeAnswer) => [credInterfaceResp, challengeAnswer] as [Credential, ChallengeAnswer])
        );
      })
    );
  }

  public handleWebAuthNChallenge(): void {
    const endpoints = this.data.webauthnEnrollmentList.map((item) => item.metadata.id);
    this.invokeWebAuthn$(endpoints)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (ChallengeAnswer) => {
          this.dialogRef.close('success');
          return;
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
