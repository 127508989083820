import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  TokensService,
  UsersService,
  ListUserResourceAccessInfoRequestParams,
  UserDesktopAccessInfoStatus,
  ListUserDesktopAccessInfoResponse,
} from '@agilicus/angular';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { map, Subject } from 'rxjs';
import UI from '../../no-vnc/app/ui.js';
import { ResourceType } from '../resource-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { URLGenerator } from '@app/url-generator';
import { clipboardCopy, ClipboardSyncher } from '@app/vnc-utils';
import { VncMfaService } from '@app/services/vnc-mfa/vnc-mfa.service';

@Component({
  selector: 'app-vnc-desktop',
  templateUrl: './vnc-desktop.component.html',
  styleUrls: ['./vnc-desktop.component.scss'],
})
export class VncDesktopComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private desktopInfo: UserDesktopAccessInfoStatus;
  private desktopId: string;
  private clipboardSync: ClipboardSyncher = new ClipboardSyncher(UI);

  constructor(
    private tokensService: TokensService,
    private authService: AuthService,
    private routeParams: ActivatedRoute,
    private users: UsersService,
    private router: Router,
    private vncMfaService: VncMfaService
  ) {
    if (this.routeParams.params) {
      this.routeParams.params.subscribe((params) => {
        this.desktopId = params['desktopId'];
      });
    }
  }

  public ngOnInit(): void {
    if (!this.desktopId) {
      this.router.navigate(['']);
    }

    UI.clipboardCopy = clipboardCopy.bind(this);
    this.getDesktopInformation();
  }

  public ngAfterViewInit(): void {
    this.clipboardSync.start();
  }

  public getDesktopInformation(): void {
    const userId = localStorage.getItem('user_id') || '';
    const orgId = localStorage.getItem('org_id') || '';
    const params: ListUserResourceAccessInfoRequestParams = {
      user_id: userId,
      org_id: orgId,
      resource_type: ResourceType.desktop,
    };

    this.users
      .listDesktopAccessInfo(params)
      .pipe(map((listDesktopInfo) => this.filterDesktop(listDesktopInfo)))
      .subscribe((desktopInfo) => {
        const urlGenerator = new URLGenerator(desktopInfo, this.tokensService, this.authService);
        UI.getURL = async () => await urlGenerator.getURLWithValidToken();
        this.vncMfaService.checkMFA(urlGenerator, UI);
      });
  }

  public filterDesktop(listDesktopInfo: ListUserDesktopAccessInfoResponse): UserDesktopAccessInfoStatus {
    const desktop = listDesktopInfo.user_desktop_access_info.find((desktop) => desktop.status.resource_id === this.desktopId);
    return desktop.status;
  }

  public ngOnDestroy(): void {
    this.clipboardSync.stop();
    if (UI.clipboardCopy) {
      delete UI.clipboardCopy;
    }
    if (UI.getURL) {
      delete UI.getURL;
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
