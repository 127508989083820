<div class="icon-grid-container">
  <!-- Display suborganizations as folders -->
  <app-app-icon-folder
    *ngIf="!labelClicked;"
    [folderList]="folderList"
    [isSmallScreen]="isSmallScreen"
    [resourceType]="resourceType"
    (subOrgClick)="onSubOrgClick()"
  ></app-app-icon-folder>

  <ng-container *ngIf="!labelClicked; else resourcesAndLabelsView">
    <!-- Display labels -->
    <app-app-label-folder
      [labelsList]="labelsList"
      [isSmallScreen]="isSmallScreen"
      (labelClick)="onLabelClickEvent($event)"
    ></app-app-label-folder>
  </ng-container>

  <ng-template #resourcesAndLabelsView>
    <ng-container *ngIf="filteredResourcesInLabel.length > 0">
      <!-- Display resources within the selected label -->
      <app-app-icon
        [resourceList]="filteredResourcesInLabel"
        [resourceType]="resourceType"
        [isSmallScreen]="isSmallScreen"
        (updateRequestIcons)="updateRequestIconsEvent()"
      ></app-app-icon>
    </ng-container>
  </ng-template>

  <!-- Display resources not inside any label -->
  <ng-container *ngIf="filteredResourcesNotInLabels.length > 0 && !labelClicked">
    <app-app-icon
      [resourceList]="filteredResourcesNotInLabels"
      [resourceType]="resourceType"
      [isSmallScreen]="isSmallScreen"
      (updateRequestIcons)="updateRequestIconsEvent()"
    ></app-app-icon>
  </ng-container>
</div>
