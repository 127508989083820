import { MatDialogConfig } from '@angular/material/dialog';
import { PageInfoData } from '@app/page-info-utils';

export interface DialogButtonOptions {
  confirm: string;
  cancel: string;
}

export interface ConfirmationDialogData {
  messagePrefix?: string;
  message?: string;
  buttonText?: DialogButtonOptions;
  informationDialog?: boolean;
  orgId?: string;
  icon?: string;
  productGuideData?: PageInfoData;
  showMoreText?: string;
}

export function getDefaultDialogConfig(config: MatDialogConfig): MatDialogConfig {
  return {
    width: '95%',
    maxWidth: '750px',
    minWidth: '0px',
    ...config,
  };
}

export function createDialogData(messagePrefix: string, message: string): ConfirmationDialogData {
  return {
    messagePrefix,
    message,
  };
}

export function getDefaultLogoDialogConfig(config: MatDialogConfig): MatDialogConfig {
  return {
    ...getDefaultDialogConfig(config),
    maxWidth: '400px',
  };
}
