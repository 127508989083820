/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { OrgBasicInfo } from '../org-basic-info';
import { ResourceAccess } from '../resource-access';
import { ResourceType } from '../resource-type.enum';
import { LabelInfo } from '@app/app-launcher/app-launcher.component';

@Component({
  selector: 'app-app-icon-folder-structure',
  templateUrl: './app-icon-folder-structure.component.html',
  styleUrls: ['./app-icon-folder-structure.component.scss'],
})
export class AppIconFolderStructureComponent implements OnInit, OnChanges {
  @Input() public folderList: Array<OrgBasicInfo> = [];
  @Input() public resourceList: Array<ResourceAccess> = [];
  @Input() public labelsList: Array<LabelInfo> = [];
  @Input() public resourcesNotInLabels: Array<ResourceAccess> = [];
  @Input() public isSmallScreen = false;
  @Input() public resourceType = ResourceType.application;
  @Output() public updateRequestIcons = new EventEmitter();
  @Input() public parentOrg: string = '';
  public resourcesInLabel: Array<ResourceAccess> = [];
  public labelClicked: boolean = false; // Track if a label has been clicked
  public selectedLabel: string;

  public filteredResourcesInLabel: ResourceAccess[] = [];
  public filteredResourcesNotInLabels: ResourceAccess[] = [];

  constructor() {}

  public ngOnInit(): void {
    this.filteredResourcesNotInLabels = [...this.resourcesNotInLabels];
    this.updateResourcesNotInLabels();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceList) {
      this.updateResourcesNotInLabels();
    }
  }

  public onSubOrgClick(): void {
    this.updateResourcesNotInLabels();
  }

  private updateResourcesNotInLabels(): void {
    this.selectedLabel = undefined;
    this.labelClicked = false;
    this.resourcesInLabel = [];
    this.filteredResourcesInLabel = [];
    this.filteredResourcesNotInLabels = [...this.resourcesNotInLabels];
  }

  public onLabelClickEvent(label: LabelInfo): void {
    this.resourcesInLabel = this.resourceList.filter((resource) => label.objects.some((obj) => obj.object_id === resource.id));
    this.filteredResourcesInLabel = [...this.resourcesInLabel];
    this.labelClicked = true;
    this.selectedLabel = label.name;
  }

  public goBack(): void {
    this.updateResourcesNotInLabels();
  }

  public updateRequestIconsEvent(): void {
    this.updateRequestIcons.emit();
  }

  public applyResourceFilter(selectedResourceTypes: { [key: string]: boolean }): void {
    const filterResources = (resources: ResourceAccess[]) => resources.filter((resource) => selectedResourceTypes[resource.resource_type]);

    if (Object.values(selectedResourceTypes).every((value) => !value)) {
      if (this.labelClicked) {
        this.filteredResourcesInLabel = [...this.resourcesInLabel];
      } else {
        this.filteredResourcesNotInLabels = [...this.resourcesNotInLabels];
      }
      return;
    }

    if (this.labelClicked) {
      this.filteredResourcesInLabel = filterResources(this.resourcesInLabel);
    } else {
      this.filteredResourcesNotInLabels = filterResources(this.resourcesNotInLabels);
    }
  }

  public applySearchFilter(searchTerm: string): void {
    const filterResourcesByName = (resources: ResourceAccess[]) =>
      resources.filter((resource) => resource.name.toLowerCase().includes(searchTerm));

    if (!searchTerm) {
      if (this.labelClicked) {
        this.filteredResourcesInLabel = [...this.resourcesInLabel];
      } else {
        this.filteredResourcesNotInLabels = [...this.resourcesNotInLabels];
      }
      return;
    }

    if (this.labelClicked) {
      this.filteredResourcesInLabel = filterResourcesByName(this.resourcesInLabel);
    } else {
      this.filteredResourcesNotInLabels = filterResourcesByName(this.resourcesNotInLabels);
    }
  }
}
