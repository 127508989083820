import {
  Challenge,
  ChallengeAnswer,
  ChallengesService,
  GetAnswerRequestParams,
  SessionChallenge,
  SessionChallengeMessage,
  SessionChallengeStatus,
  TokensService,
  WebAuthNEnrollment,
} from '@agilicus/angular';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { getDefaultDialogConfig } from '@app/dialog-utils';
import { MfaChallengeDialogComponent, MFAChallengeDialogData } from '@app/mfa-challenge-dialog/mfa-challenge-dialog.component';
import { Observable, catchError, concatMap, forkJoin, map, of } from 'rxjs';
import { convertFragmentToChallenge } from '@app/utils/challenge.utils';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { getIgnoreErrorsHeader } from '@app/http-interceptors/http-interceptor-utils';
import { rawListeners } from 'process';

interface StateError {
  message: string;
  details?: string;
}
interface State {
  challengeSuccess?: boolean;
  error?: StateError;
}

@Component({
  selector: 'app-webpush-mfa-challenge',
  templateUrl: './webpush-mfa-challenge.component.html',
  styleUrls: ['./webpush-mfa-challenge.component.scss'],
})
export class WebPushMfaChallengeComponent implements OnInit {
  public state$: Observable<State>;

  constructor(
    private route: ActivatedRoute,
    private challengeDialog: MatDialog,
    private challenges: ChallengesService,
    private tokens: TokensService
  ) {}
  public ngOnInit(): void {
    this.state$ = this.route.queryParams.pipe(
      concatMap((params: Params) => {
        const code = params.code;
        const challengeID = params.challenge_id;
        if (!code) {
          throw new Error('missing code query parameter');
        }

        if (!challengeID) {
          throw new Error('missing challenge_id query parameter');
        }

        const reqParams: GetAnswerRequestParams = {
          challenge_id: challengeID,
          challenge_answer: code,
          allowed: true,
          challenge_type: 'code',
        };

        return this.challenges.getAnswer(reqParams, 'body', getIgnoreErrorsHeader());
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          throw httpErrorToStateError(err);
        }
        throw err;
      }),
      concatMap((answer: ChallengeAnswer) => {
        const answer_data = answer.spec.answer_data as SessionChallengeMessage | undefined;
        if (!answer_data) {
          throw new Error('missing answer data');
        }
        const sessionChallengeStatus = (answer_data.session_challenge as SessionChallenge | undefined)?.status;
        if (!sessionChallengeStatus) {
          throw new Error('challenge answer was malformed');
        }

        const token = answer_data.token;
        if (!token) {
          throw new Error('challenge answer missing token');
        }

        const challenge = sessionChallengeStatus.challenge;
        if (!challenge?.metadata?.id) {
          throw new Error('challenge missing id');
        }
        if (!challenge.spec) {
          throw new Error('challenge missing spec');
        }
        if (!challenge.status) {
          throw new Error('challenge missing status');
        }

        const webauthnEnrollmentList = sessionChallengeStatus.webauthn_enrollments;
        const challengeDescription = sessionChallengeStatus?.description;

        const data: MFAChallengeDialogData = {
          challenge: challenge,
          webauthnEnrollmentList: webauthnEnrollmentList,
          challengeDescription: challengeDescription,
        };
        const dialogRef = this.challengeDialog.open(
          MfaChallengeDialogComponent,
          getDefaultDialogConfig({
            data,
            disableClose: true,
            width: '100%',
            maxWidth: 'none',
          })
        );
        return forkJoin([dialogRef.afterClosed(), of(token)]);
      }),
      concatMap(([status, token]: [string, string]) => {
        if (status === 'success') {
          const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
          return this.tokens.updateSessionChallenge({ SessionChallenge: {} }, 'body', headers);
        }
        return of(undefined);
      }),
      map((challenge: SessionChallenge | undefined) => {
        const result: State = {
          challengeSuccess: !!challenge,
        };

        if (window.history.length > 1) {
          window.history.back();
        } else {
          window.close();
        }
        return result;
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          return of({ error: httpErrorToStateError(err) });
        }

        return of({
          error: {
            message: err.message,
            details: err.details,
          },
        });
      })
    );
  }
}

function httpErrorToStateError(err: HttpErrorResponse): StateError {
  const newErr: StateError = {
    message: 'Failed to claim challenge. Please try your request again',
    details: err.message,
  };
  return newErr;
}
