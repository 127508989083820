import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getIgnoreErrorsKey, getIgnoreErrorsValue } from './http-interceptors/http-interceptor-utils';

@Directive({
  selector: '[appCheckImage]',
})
export class CheckImageDirective implements OnInit {
  @Input() src: string;
  private fallback: string = '/assets/icons/my-login.svg';

  constructor(private el: ElementRef, private http: HttpClient) {}

  ngOnInit() {
    this.el.nativeElement.src = this.src;
    this.checkImageExists(this.src).then((exists) => {
      if (exists) {
        this.checkImage(this.src);
      } else {
        this.el.nativeElement.src = this.fallback;
      }
    });
  }

  private async checkImageExists(url: string): Promise<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append(getIgnoreErrorsKey(), getIgnoreErrorsValue());
    try {
      const response = await this.http.head(url, { headers, observe: 'response' }).toPromise();
      return response.status === 200;
    } catch (error) {
      return false;
    }
  }

  private checkImage(url: string): void {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        const pixels = imageData.data;

        let hasTransparency = false;
        let hasBlue = false;

        for (let i = 0; i < pixels.length; i += 4) {
          const red = pixels[i];
          const green = pixels[i + 1];
          const blue = pixels[i + 2];
          const alpha = pixels[i + 3];

          if (alpha < 255) {
            hasTransparency = true;
          }

          if (blue > 200 && red < 100 && green < 100) {
            hasBlue = true;
          }

          if (hasTransparency && hasBlue) {
            this.el.nativeElement.style.backgroundColor = 'white';
            break;
          }
        }
      }
    };
    img.onerror = () => {
      this.el.nativeElement.src = this.fallback;
    };
  }
}
