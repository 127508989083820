<div *ngFor="let label of labelsList" class="icon-container">
    <div
      class="icon-inner-container"
      (click)="onLabelClick(label)"
    >
      <div class="icon-img-container">
        <mat-icon
          class="default-icon"
          [ngClass]="{'default-icon-small': isSmallScreen}"
        >
          label
        </mat-icon>
      </div>
      <div class="app-name-container">
        <p>{{ label.name }}</p>
      </div>
    </div>
</div>
