import { ResourceAccessState } from './resource-access-state';
import { ResourceComponentState } from './resource-component-state';
import { ResourceType } from './resource-type.enum';
import * as pluralize from 'pluralize';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  APIKey,
  CreateApiKeyRequestParams,
  CreateTokenRequest,
  CreateTokenRequestParams,
  DisplayInfo,
  ListUserFileShareAccessInfoRequestParams,
  TokensService,
  User,
  UserFileShareAccessInfo,
  UsersService,
  RawToken,
} from '@agilicus/angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { convertDaysToSeconds, getFutureDateFromSeconds } from './date-utils';
import { ResourceAccess } from './resource-access';

export interface GetApiKeyData {
  userId: string;
  orgId: string;
  resourceId: string;
  resourceName: string;
  tokensService: TokensService;
  expiry?: Date;
  resourceType?: string;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function addHTTPSToURL(url: string): string {
  return 'https://' + url;
}

export function getEmptyResourceComponentState(resourceType?: ResourceType): ResourceComponentState {
  return {
    resourceAccessInfo: [],
    userRootOrgId: '',
    userId: '',
    orgIdParam: '',
    title: '',
  };
}

export function getEmptyResourceAccessState(): ResourceAccessState {
  return {
    grantedResources: {
      orgIdToIconMap: new Map(),
    },
    requestedResources: {
      orgIdToIconMap: new Map(),
    },
    allResources: {
      orgIdToIconMap: new Map(),
    },
    // request flow is disabled by default
    requestFlowDisabled: true,
  };
}

export function pluralizeString(str: string): string {
  return pluralize(str);
}

export function getFileShareDefaultRole(): string {
  return 'viewer';
}

export function getDesktopDefaultRole(): string {
  return 'owner';
}

export function getLauncherDefaultRole(): string {
  return 'owner';
}

export function getApplicationDefaultRole(): string {
  return 'owner';
}

/**
 * Checks if the menu link is currently selected.
 */
export function isLinkActive(routerLink: string, router: Router): boolean {
  if (router !== undefined) {
    const routerUrlArray = router.url.split('?');
    const targetLink = routerUrlArray[0];
    if (targetLink === routerLink) {
      return true;
    }
  }
  return false;
}

export function addOrgToRoute(router: Router, route: ActivatedRoute, orgId: string): void {
  router.navigate(['.'], { relativeTo: route, queryParams: { org_id: orgId } });
}

export function getFileShareScope(shareId: string): string {
  return `urn:agilicus:fileshare:${shareId}:*`;
}

export function getAllFileSharesScope(): string {
  return 'urn:agilicus:fileshare:*?';
}

export function getApiKey(getApiKeyData: GetApiKeyData): Observable<string> {
  let resourceType = ResourceType.share;
  if (getApiKeyData.resourceType === ResourceType.desktop) {
    resourceType = ResourceType.desktop;
  }
  if (getApiKeyData.resourceType === ResourceType.ssh) {
    resourceType = ResourceType.ssh;
  }
  if (getApiKeyData.resourceType === ResourceType.launcher) {
    resourceType = ResourceType.launcher;
  }
  const newApiKey: APIKey = {
    spec: {
      user_id: getApiKeyData.userId,
      org_id: getApiKeyData.orgId,
      scopes: [
        getResourceTypeScope(getApiKeyData.resourceId, resourceType),
        'urn:agilicus:api:challenges:self',
        'urn:agilicus:api:traffic-tokens:user',
      ],
      name: getAPIKeyName(getApiKeyData.resourceName),
      label: getAPIKeyLabel(resourceType),
      expiry: getFutureDateFromSeconds(getDefaultApiKeyDurationInSeconds()),
    },
  };
  if (!!getApiKeyData.expiry) {
    newApiKey.spec.expiry = getApiKeyData.expiry;
  }
  const createApiKeyRequestParams: CreateApiKeyRequestParams = {
    APIKey: newApiKey,
  };
  return getApiKeyData.tokensService.createApiKey(createApiKeyRequestParams).pipe(
    map((createApiKeyResp) => {
      if (!createApiKeyResp.status || !createApiKeyResp.status.api_key) {
        return '';
      }
      return createApiKeyResp.status.api_key;
    })
  );
}

export function getResourceAccessToken(getApiKeyData: GetApiKeyData, resource: ResourceAccess): Observable<RawToken> {
  const newToken: CreateTokenRequest = {
    sub: getApiKeyData.userId,
    org: getApiKeyData.orgId,
    audiences: ['urn:api:agilicus', 'urn:api:agilicus:gateway'],
    time_validity: { duration: getResourceAuthzDurationInSeconds() },
    scopes: [
      getResourceTypeScope(getApiKeyData.resourceId, resource.resource_type),
      'urn:agilicus:api:users:self',
      'urn:agilicus:api:applications:self',
      'urn:agilicus:api:challenges:self',
      'urn:agilicus:api:traffic-tokens:user',
    ],
    create_refresh_token: true,
  };
  const createTokenRequestParams: CreateTokenRequestParams = {
    CreateTokenRequest: newToken,
  };
  return getApiKeyData.tokensService.createToken(createTokenRequestParams).pipe(
    map((createTokenResp) => {
      return createTokenResp;
    })
  );
}

export function getResourceTypeScope(resourceId: string, resourceType: string): string | undefined {
  if (resourceType === ResourceType.desktop) {
    return getDefaultDesktopScope(resourceId);
  }
  if (resourceType === ResourceType.share) {
    return getFileShareScope(resourceId);
  }
  if (resourceType === ResourceType.ssh) {
    return getDefaultSshScope(resourceId);
  }
  if (resourceType === ResourceType.launcher) {
    return getDefaultLauncherScope();
  }
  return undefined;
}

export function getDefaultDesktopScope(id: string): string {
  return `urn:agilicus:${ResourceType.desktop}:${id}:*`;
}

export function getDefaultSshScope(id: string): string {
  return `urn:agilicus:${ResourceType.ssh}:${id}:*`;
}

export function getDefaultLauncherScope(): string {
  return `urn:agilicus:application_service:*:owner?`;
}

export function getAPIKeyName(resourceName: string): string {
  return `${resourceName}`;
}

export function getAPIKeyLabel(resourceType: ResourceType): string {
  return `profile-${pluralizeString(resourceType)}`;
}

export function getDefaultApiKeyDurationInSeconds(): number {
  return convertDaysToSeconds(1.5);
}

export function getResourceAuthzDurationInSeconds(): number {
  // 1 day
  return convertDaysToSeconds(1);
}

export function getShareAccessInfo(userId: string, orgId: string, usersService: UsersService): Observable<Array<UserFileShareAccessInfo>> {
  const params: ListUserFileShareAccessInfoRequestParams = {
    user_id: userId,
    org_id: orgId,
  };
  return usersService.listUserFileShareAccessInfo(params).pipe(
    map(
      (sharesAccessresp) => {
        return sharesAccessresp.user_file_share_access_info;
      },
      catchError((_) => {
        return of([]);
      })
    )
  );
}

export function getDefaultIconPurpose(): string {
  return 'agilicus-profile';
}

export function getIconURIFromResourceDisplayInfo(displayInfo: DisplayInfo): string {
  const logo = displayInfo?.icons
    ? displayInfo.icons.find((icon) => icon.purposes.some((purpose) => purpose === getDefaultIconPurpose()))
    : undefined;
  return logo?.uri || '';
}

export function getUserNameFromUser(user: User): string {
  let userName = '';
  if (!!user.first_name) {
    userName += `${user.first_name} `;
  }
  if (!!user.last_name) {
    userName += `${user.last_name}`;
  }
  return userName.trim();
}

export function arrayBufferToStr(buf: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buf);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function stringToBufBase(buf: ArrayBuffer, bufView: Uint8Array | Uint16Array, str: string): ArrayBuffer {
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export function stringToArrayBufferU8(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  return stringToBufBase(buf, bufView, str);
}

export function stringToArrayBuffer(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  const bufView = new Uint16Array(buf);
  return stringToBufBase(buf, bufView, str);
}

export function getOrgIdFromRouter(routerQueryParams: Params): string {
  return routerQueryParams.org_id || getStoredOrgId();
}

export function getStoredOrgId(): string {
  return localStorage.getItem('org_id') || '';
}

export function getRouterLinkFromPath(): string {
  const fullPath = window.location.pathname;
  const paths = fullPath.split('/');
  const routerLink = '/' + paths[1];
  return routerLink;
}
