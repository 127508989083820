<div *ngFor="let folder of folderList" class="icon-container">
  <div
    class="icon-inner-container"
    (click)="routeToOrgResources(folder.orgId)"
  >
    <div class="icon-img-container">
      <mat-icon
        class="default-icon"
        [ngClass]="{'default-icon-small': isSmallScreen}"
      >
        folder
      </mat-icon>
    </div>
    <div class="app-name-container">
      <p>{{ folder.orgName }}</p>
    </div>
  </div>
</div>
